.author {
    img {
        display: block;
        width: 8rem;
        height: 8rem;
        border-radius: 1rem;
        margin-right: 2rem; }

    p {
        display: block;
        padding: 1.75rem 0rem 1.75rem 0rem;
        font-size: 1.1rem;
        line-height: 1.7rem; } }

.social {
    padding: 1rem 0rem  1rem 0rem;

    p {
        display: block;
        padding: .15rem 0rem .15rem 0rem;
        line-height: 1.7rem;

        img {
            width: 1em;
            position: relative;
            top: 0.1rem;
            margin-right: 0.4rem; } } }

.posts {
    h2 {
        font-size: 1.75rem;
        margin-top: 2rem;
        margin-bottom: 1rem; }

    h3 {
        font-size: 1.4rem;
        margin-top: 2rem;
        margin-bottom: 2rem; }

    p {
        font-size: 1.2rem;
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        span {
            display: block;
            color: #6b717f;
            font-size: 0.9rem; }

        a {
            display: inline-block; } } }
